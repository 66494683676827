import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";
import { useAppContext } from "../services/authContext";
import ClinicDataService from "../services/ClinicDataService";

function AccountSetting(props) {
  const { displayMessage, displayAlert } = useAppContext();
  const [uploadFile, setUploadFile] = useState("");
  const [accountInfo, setAccountInfo] = useState({});
  const [editAccount, setEditAccount] = useState({});
  const [pwCombo, setPwCombo] = useState({});
  const [confirmPw, setConfirmPw] = useState("");

  function handleFileChange(event) {
    setUploadFile(event.target.files[0]);
  }

  function back2Default(event) {
    props.history.push("/");
  }

  useEffect(() => {
    getAccountInfo();
  }, []);

  function getAccountInfo() {
    ClinicDataService.getAccountInfo(localStorage.getItem("accountId")).then(
      (response) => {
        setAccountInfo(response.data);
        setEditAccount(response.data);
      },
      (error) => console.log(error)
    );
  }

  const changeAccountInfo = (keyword) => (event) => {
    setEditAccount({ ...editAccount, [keyword]: event.target.value });
  };

  function updateAccount() {
    ClinicDataService.updateAccount(editAccount).then(
      (response) =>
        displayMessage("The account information has been successfully updated"),
      (error) => displayAlert("The update of account information failed")
    );
  }

  const changePasswords = (keyword) => (event) => {
    setPwCombo({ ...pwCombo, [keyword]: event.target.value });
  };

  function changeConfirmPw(event) {
    setConfirmPw(event.target.value);
  }

  function updatePassword() {
    ClinicDataService.updatePassword(pwCombo).then(
      (response) => console.log(response.data),
      (error) => console.log(error)
    );
  }

  return (
    <React.Fragment>
      <Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 50 }}
          onClick={back2Default}
        >
          Back
        </Button>
      </Typography>
      <Container>
        <Grid container className="add-info-section">
          <Grid item md={6} className="add-info-left">
            <FormControl className="left-content" style={{ marginBottom: 10 }}>
              <Avatar src="/static/images/clinic.svg" className="big-icon" />
              <TextField type="file" onChange={handleFileChange} />
            </FormControl>
            <Typography style={{ marginBottom: 30 }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 150 }}
              >
                Upload
              </Button>
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormLabel>First name</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={editAccount.firstName}
                  onChange={changeAccountInfo("firstName")}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Middle name</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={editAccount.middleName}
                  onChange={changeAccountInfo("middleName")}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Last name</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={editAccount.lastName}
                  onChange={changeAccountInfo("lastName")}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Phone</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={changeAccountInfo("phone")}
                />
              </Grid>
              <Grid item md={3}>
                <Button variant="contained">Cancel</Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateAccount}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} className="add-info-right">
            <Grid container spacing={3} style={{ marginTop: 300 }}>
              <Grid item md={4}>
                <FormLabel>Old password</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="password"
                  value={pwCombo.password}
                  onChange={changePasswords("previousPassword")}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>New password</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="password"
                  onChange={changePasswords("password")}
                />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Confirm Password</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="password"
                  value={confirmPw}
                  onChange={changeConfirmPw}
                />
              </Grid>
              <Grid item md={3}>
                <Button variant="contained">Cancel</Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updatePassword}
                >
                  Update
                </Button>
              </Grid>
              <Grid item md={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default AccountSetting;
