import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Container,
  Divider,
  InputLabel,
  FormControl,
  Input,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClinicDataService from "../services/ClinicDataService";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAppContext } from "../services/authContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function ClinicIssue(props) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const { displayMessage, displayAlert, clinicId } = useAppContext();
  const [issueList, setIssueList] = useState([]);
  const [issueFile, setIssueFile] = useState([]);
  const [issuesFiles, setIssuesFiles] = useState(new Map());

  useEffect(() => {
    populateIssues();
  }, []);

  function populateIssues() {
    ClinicDataService.getIssue(clinicId).then(
      (response) => setIssueList([...response.data]),
      (error) => {
        console.log(error);
      }
    );
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  function handleIssueFile(event) {
    setIssueFile(event.target.files[0]);
  }

  const handleExistingIssueFile = (issueId) => (event) => {
    let tempMap = new Map(issuesFiles);
    tempMap.set(issueId, event.target.files[0]);
    setIssuesFiles(new Map(tempMap));
  };

  const uploadFile = (issueId, index) => (event) => {
    const data = new FormData();
    data.append("files", issuesFiles.get(issueId));
    if (issuesFiles.get(issueId)) {
      ClinicDataService.uploadIssueFile(issueId, data).then(
        (response) => {
          ClinicDataService.getIssueFiles(issueId).then(
            (response) => {
              let tempIssues = [...issueList];
              tempIssues[index].clinicReportIssueAttachments = response.data;
              setIssueList([...tempIssues]);
            },
            (error) => console.log(error)
          );
          let tempMap = new Map(issuesFiles);
          tempMap.delete(issueId);
          setIssuesFiles(tempMap);
          displayMessage("The file has been successfully uploaded");
        },
        (error) => console.log(error)
      );
    }
  };

  function saveIssue() {
    ClinicDataService.createIssue({
      id: 0,
      clinicId: clinicId,
      status: "0",
      reportIssue: message,
    }).then(
      (response) => {
        const data = new FormData();
        data.append("files", issueFile);
        ClinicDataService.uploadIssueFile(response.data, data).then(
          (response) => {
            populateIssues();
            displayMessage("The issue has been successfully reported.");
            setMessage("");
          },
          (error) => console.log(error)
        );
      },
      (error) => displayAlert(error)
    );
  }

  const deleteIssue = (issueId) => (eventId) => {
    ClinicDataService.deleteIssue(issueId).then(
      (response) => console.log(response),
      (error) => console.log(error)
    );
  };

  const deleteAttachment = (issueId, attachmentId) => (event) => {
    if (window.confirm("Do you really want to delete this file?")) {
      ClinicDataService.deleteIssueFile(issueId, attachmentId).then(
        (response) => {
          populateIssues();
          displayMessage("The issue has been successfully reported.");
        },
        (error) => console.log(error)
      );
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const getReplies = (issueId, index) => (event) => {
    if (!issueList[index].replies) {
      ClinicDataService.getIssueReplies(issueId).then(
        (response) => {
          let tmpIssues = issueList;
          tmpIssues[index].replies = response.data.reverse();
          setIssueList([...tmpIssues]);
        },
        (error) => console.log(error)
      );
    }
  };

  const handleReplyChange = (index) => (event) => {
    let tmpIssues = issueList;
    tmpIssues[index].reply = event.target.value;
    setIssueList([...tmpIssues]);
  };

  const saveReply = (index) => (event) => {
    ClinicDataService.saveReply({
      reportIssueId: issueList[index].id,
      reply: issueList[index].reply,
    }).then(
      (response) => {
        ClinicDataService.getIssueReplies(issueList[index].id).then(
          (response) => {
            displayMessage("The reply has been successfully saved.");
            let tmpIssues = issueList;
            tmpIssues[index].replies = response.data.reverse();
            setIssueList([...tmpIssues]);
          },
          (error) => console.log(error)
        );
      },
      (error) => displayAlert(error)
    );
  };

  return (
    <React.Fragment>
      <Typography>
        <Button
          variant="outlined"
          onClick={props.closeNewIssue}
          color="primary"
          style={{ marginLeft: 50 }}
        >
          Back
        </Button>
      </Typography>
      <Container style={{ margin: 60 }} className="message-container">
        <Typography variant="h5">Report An Issue</Typography>
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
            <FormControl fullWidth>
              <Typography>Description of the issue</Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={8}
                variant="outlined"
                fullWidth
                value={message}
                onChange={handleMessageChange}
              />
            </FormControl>
            <FormControl style={{ marginTop: 50 }}>
              <Typography>Screenshot (Optional)</Typography>
              <TextField type="file" onChange={handleIssueFile} />
            </FormControl>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
        <Typography align="center" style={{ marginTop: 50 }}>
          <Button variant="contained" style={{ margin: 10 }}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={saveIssue}
          >
            Save
          </Button>
        </Typography>
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />
        {issueList.map((issue, index) => (
          <Accordion
            square
            key={issue.id}
            className="patient-msg-item"
            onChange={getReplies(issue.id, index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="expand-icon"
            >
              <div className="item-title">
                <div className="title title-from">
                  <div className="label">Issue No.</div>
                  <div className="content">{issue.id}</div>
                </div>
                <div className="title title-date">
                  <div className="label">Date</div>
                  <div className="content">{issue.updatedDate}</div>
                </div>
                <div className="title title-date">
                  <div className="label">Status</div>
                  <div className="content">
                    {issue.status === "0"
                      ? "Open"
                      : issue.status === "1"
                      ? "In Progress"
                      : "Resolved"}
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="item-content">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography>{issue.reportIssue}</Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography>Additional Comments:</Typography>
                </Grid>
                {issue.replies
                  ? issue.replies.map((reply) => (
                      <Grid item md={12}>
                        <Grid container>
                          <Grid item md={6}>
                            <Typography>{reply.reply}</Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>{reply.createdDate}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  : ""}
                <Grid item md={12}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        variant="outlined"
                        fullWidth
                        value={issue.reply}
                        onChange={handleReplyChange(index)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={saveReply(index)}
                      >
                        Reply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {issue.clinicReportIssueAttachments.map((attachment, index) => (
                  <Grid item md={12}>
                    <Typography>Attachment {index + 1}</Typography>
                    <Button
                      style={{
                        backgroundColor: "#3553B21A",
                        border: "1px solid #3553B2",
                        borderRadius: 5,
                        color: "#3553B2",
                        minWidth: 80,
                        textAlign: "center",
                        padding: 5,
                      }}
                      role="link"
                      onClick={() =>
                        openInNewTab(attachment.attachmentLocation)
                      }
                    >
                      View
                    </Button>
                    <IconButton
                      style={{
                        marginLeft: 30,
                      }}
                      onClick={deleteAttachment(
                        attachment.clinicReportIssueId,
                        attachment.attachmentId
                      )}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                ))}
                <Grid item md={3}>
                  <FormControl>
                    <Typography>Upload file:</Typography>
                    <TextField
                      type="file"
                      onChange={handleExistingIssueFile(issue.id)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={9}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ margin: 10 }}
                    onClick={uploadFile(issue.id, index)}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </React.Fragment>
  );
}

export default ClinicIssue;
