import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Input,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Select,
  Button,
  Table,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  MenuItem,
  Card,
  CardContent,
  Divider,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ClinicDataService from "../services/ClinicDataService";
import { createMuiTheme } from "@material-ui/core/styles";
import { fade, ThemeProvider } from "@material-ui/core/styles";
import Image from "material-ui-image";
import { ReactComponent as PhoneIcon } from "../static/images/SVGs/phone.svg";
import { ReactComponent as PhoneCallIcon } from "../static/images/SVGs/phoneCall.svg";
import { ReactComponent as EmailIcon } from "../static/images/SVGs/IconEnvelope.svg";
import { ReactComponent as PencileIcon } from "../static/images/SVGs/pencile.svg";
import SearchBar from "./SearchBar";
import SmsIcon from "@material-ui/icons/Sms";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useAppContext } from "../services/authContext";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        border: "1px solid #DEE7F6",
        minWidth: 200,
      },
      text: {
        color: "#999999",
      },
      label: {
        justifyContent: "left",
        marginLeft: 15,
      },
      endIcon: {
        marginLeft: 85,
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #DEE7F6",
        top: 1.3,
      },
      input: {
        color: "#1A568E",
        textAlign: "left",
      },
    },
    MuiInput: {
      formControl: {
        border: 0,
      },
    },
    MuiTypography: {
      body1: {
        color: "#1A568E",
      },
      displayBlock: {
        fontSize: 14,
        color: "#ffffff",
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#235FC1",
          "&:hover": {
            color: "#235FC1",
          },
        },
      },
    },
    MuiTableCell: {
      root: {},
      head: {
        color: "#777777",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  borderRadius: {
    borderRadius: "5px 0px 0px 5px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  search: {
    display: "inline",
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    height: "100%",
  },
  days: {
    "& input": {
      textAlign: "center",
      backgroundColor: "#ffffff",
    },
  },
  searchIcon: {
    display: "inline",
    height: "100%",
    width: "20%",
    padding: "10px 15px 10px 15px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #388EF2",
    borderRadius: "0px 5px 5px 0px",
    background: "#388EF2",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
    minWidth: 300,
  },
  issueBtn: {
    background: "#388EF2",
    borderRadius: 5,
    font: "normal normal 600 1em/ 1em Montserrat",
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
  languageTabs: {},
  centerInput: {
    textAlign: "center",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={8}
          value={props.intro}
          defaultValue="Default Value"
          variant="outlined"
          fullWidth
        />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabCardPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card>
          <CardContent style={{ padding: 50 }}>{children}</CardContent>
        </Card>
      )}
    </div>
  );
}

TabCardPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function IntroPanel(props) {
  const { children, value, index, ...other } = props;
  const [languageTab, setLanguageTab] = useState(0);

  function handleLanguageTabChange(event, newValue) {
    setLanguageTab(newValue);
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card style={{ width: "92%" }}>
          <CardContent style={{ padding: 30 }}>
            <AppBar
              position="static"
              color="default"
              style={{
                width: "19%",
                marginLeft: "auto",
                padding: "auto",
                backgroundColor: "#ffffff",
              }}
            >
              <Tabs
                value={languageTab}
                onChange={handleLanguageTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label="French"
                  {...a11yProps(0)}
                  style={{
                    minWidth: 0,
                    border: "0.5px solid #999999",
                    borderRadius: 5,
                  }}
                />
                <Tab
                  label="English"
                  {...a11yProps(1)}
                  style={{
                    minWidth: 0,
                    border: "0.5px solid #999999",
                    borderRadius: 5,
                  }}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={languageTab} index={0}>
              <TextField
                value={
                  props.clinic.description
                    ? JSON.parse(props.clinic.description).fr
                    : ""
                }
              />
            </TabPanel>
            <TabPanel value={languageTab} index={1}>
              <TextField
                value={
                  props.clinic.description
                    ? JSON.parse(props.clinic.description).en
                    : ""
                }
              />
            </TabPanel>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

function ClinicInfo(props) {
  const classes = useStyles();
  const { clinicId, displayMessage } = useAppContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [clinic, setClinic] = useState({});
  const [enIntro, setEnIntro] = useState("");
  const [frIntro, setFrIntro] = useState("");
  const [openHours, setOpenHours] = useState([]);
  const [newHour, setNewHour] = useState({
    day: "Monday",
    open: "9",
    openAt: "AM",
    close: "4",
    closeAt: "PM",
  });
  const [contacts, setContacts] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [reminderData, setReminderData] = useState({});
  const [reminderType, setReminderType] = useState(0);
  const [reminderDay, setReminderDay] = useState(3);
  const [editContact, setEditContact] = useState({});
  const [flyers, setFlyers] = useState([]);
  const [flyer, setFlyer] = useState("");

  useEffect(() => {
    getClinicInfo();
  }, []);

  function getClinicInfo() {
    ClinicDataService.findClinicById(clinicId).then(
      (response) => {
        setClinic(response.data);
        setFrIntro(
          response.data.description
            ? JSON.parse(response.data.description).fr
            : ""
        );
        setEnIntro(
          response.data.description
            ? JSON.parse(response.data.description).en
            : ""
        );
        ClinicDataService.findOpenHoursById(clinicId).then(
          (response) => {
            const tempHours = [];
            const hoursStr = response.data.en;
            const hoursArray = hoursStr.split("\n");
            hoursArray.forEach((hour) => {
              const ary = hour.split(" ");
              tempHours.push({
                day: ary[0],
                open: ary[1] + " " + ary[2].toUpperCase(),
                close: ary[4] + " " + ary[5].toUpperCase(),
              });
            });
            setOpenHours([...tempHours]);
          },
          (error) => console.log(error)
        );
        findDefaultReminders();
        populateContacts();
        populateFlyers();
        populateFeedback();
        ClinicDataService.getClinicContracts(clinicId).then(
          (response) => setContracts(response.data),
          (error) => console.log(error)
        );
      },
      (error) => console.log(error)
    );
  }

  function populateContacts() {
    ClinicDataService.findAllClinicContacts(clinicId).then(
      (response) => setContacts(response.data),
      (error) => console.log(error)
    );
  }

  function populateFlyers() {
    ClinicDataService.getFlyers(clinicId).then(
      (response) => setFlyers(response.data),
      (error) => console.log(error)
    );
  }

  function populateFeedback() {
    ClinicDataService.getFeedback(clinicId).then(
      (response) => setFeedbackList(response.data),
      (error) => console.log(error)
    );
  }

  const changeEnIntro = (event) => {
    setEnIntro(event.target.value);
  };

  const changeFrIntro = (event) => {
    setFrIntro(event.target.value);
  };

  function updateIntro() {
    ClinicDataService.updateClinicInfo({
      id: clinicId,
      description: JSON.stringify({ en: enIntro, fr: frIntro }),
    }).then(
      (response) =>
        displayMessage("Introduction has been successfully updated."),
      (error) => console.log(error)
    );
  }

  function findDefaultReminders() {
    ClinicDataService.getDefaultReminders(clinicId).then(
      (response) => {
        setReminderData(response.data);
        const tempReminders = [];
        const phoneReminders = response.data.numberOfDaysInAdvanceForPhone.split(
          "-"
        );
        phoneReminders.forEach((reminder) => {
          tempReminders.push({ type: "Phone", days: reminder });
        });
        const emailReminders = response.data.numberOfDaysInAdvanceForEmail.split(
          "-"
        );
        emailReminders.forEach((reminder) => {
          tempReminders.push({ type: "Email", days: reminder });
        });
        const smsReminders = response.data.numberOfDaysInAdvanceForTm.split(
          "-"
        );
        smsReminders.forEach((reminder) => {
          tempReminders.push({ type: "SMS", days: reminder });
        });
        setReminders([...tempReminders]);
      },
      (error) => console.log(error)
    );
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function updateOpenHours(currentHours) {
    const tempHours = [];
    currentHours.forEach((hour) => {
      tempHours.push(hour.day + " " + hour.open + " - " + hour.close);
    });
    console.log(tempHours.join("\n"));
    ClinicDataService.updateOpenHours(clinicId, {
      en: tempHours.join("\n"),
      fr: "",
    }).then(
      (response) => {
        displayMessage("Open hours have been successfully updated.");
        setOpenHours([...currentHours]);
      },
      (error) => console.log(error)
    );
  }

  function addOpenHour() {
    let tempOpenHours = [...openHours];
    tempOpenHours.push({
      day: newHour.day,
      open: newHour.open + " " + newHour.openAt,
      close: newHour.close + " " + newHour.closeAt,
    });
    updateOpenHours(tempOpenHours);
  }

  const deleteOpenHour = (index) => (event) => {
    let tempOpenHours = [...openHours];
    tempOpenHours.splice(index, 1);
    updateOpenHours(tempOpenHours);
  };

  const handleHourChange = (keyword) => (event) => {
    setNewHour({ ...newHour, [keyword]: event.target.value });
  };

  const deleteContact = (contactId) => (event) => {
    ClinicDataService.deleteClinicContact(contactId).then(
      (response) => {
        populateContacts();
        displayMessage("The contact has been successfully deleted");
      },
      (error) => console.log(error)
    );
  };

  const update = (contactId) => (event) => {
    let index = contacts.findIndex((element) => element.id === contactId);
    ClinicDataService.updateClinicContact(contacts[index]).then(
      (response) => {
        populateContacts();
        displayMessage("The contact has been successfully updated");
      },
      (error) => console.log(error)
    );
  };

  const updateContactInfo = (contactId, keyword) => (event) => {
    let tmpContacts = [...contacts];
    let index = tmpContacts.findIndex((element) => element.id === contactId);
    let tmpContact = {
      ...tmpContacts[index],
      [keyword]: event.target.value,
    };
    tmpContacts[index] = tmpContact;
    setContacts([...tmpContacts]);
  };

  const changeContactInfo = (keyword) => (event) => {
    setEditContact({ ...editContact, [keyword]: event.target.value });
  };

  function addContact() {
    ClinicDataService.createClinicContact(clinicId, editContact).then(
      (response) => {
        populateContacts();
        displayMessage("The contact has been successfully added");
      },
      (error) => console.log(error)
    );
  }

  const deleteReminder = (index) => (event) => {
    let tempReminders = [...reminders];
    tempReminders.splice(index, 1);
    saveReminders(tempReminders);
  };

  function saveReminders(tempReminders) {
    let phoneReminders = tempReminders
      .filter((reminder) => reminder.type === "Phone" && reminder.days !== "")
      .map((reminder) => reminder.days)
      .join("-");
    let emailReminders = tempReminders
      .filter((reminder) => reminder.type === "Email" && reminder.days !== "")
      .map((reminder) => reminder.days)
      .join("-");
    let smsReminders = tempReminders
      .filter((reminder) => reminder.type === "SMS" && reminder.days !== "")
      .map((reminder) => reminder.days)
      .join("-");
    ClinicDataService.updateDefaultReminders({
      ...reminderData,
      numberOfDaysInAdvanceForPhone: phoneReminders,
      numberOfDaysInAdvanceForEmail: emailReminders,
      numberOfDaysInAdvanceForTm: smsReminders,
    }).then(
      (response) => {
        findDefaultReminders();
        displayMessage("The default reminders have been successfully updated");
      },
      (error) => console.log(error)
    );
  }

  function addReminder() {
    let tempReminders = [...reminders];
    if (!reminderDay) {
      window.alert("Please enter the reminder day");
      return;
    }
    switch (reminderType) {
      case 0:
        tempReminders.push({ type: "Phone", days: reminderDay });
        break;
      case 1:
        tempReminders.push({ type: "Email", days: reminderDay });
        break;
      case 2:
        tempReminders.push({ type: "SMS", days: reminderDay });
        break;
      default:
        console.log("no reminder is add!!!");
    }
    saveReminders(tempReminders);
  }

  const changeReminderType = (event) => {
    setReminderType(event.target.value);
  };

  const changeReminderDay = (event) => {
    setReminderDay(event.target.value);
  };

  const downloadContract = (contractId) => (event) => {
    ClinicDataService.getContractFile(contractId).then(
      (response) => {
        console.log(response);
        window.open(URL.createObjectURL(new Blob([response.data])));
        /*let objURL = URL.createObjectURL(response.data);
        const tmp1 = document.createElement("a");
        tmp1.href = objURL;
        /*tmp1.download = response.headers["content-disposition"].split(
          "filename="
        )[1];
        tmp1.download = "contract" + contractId;
        console.log(tmp1.download);
        document.body.appendChild(tmp1);
        tmp1.click();
        URL.revokeObjectURL(objURL);
        tmp1.remove(); */
      },
      (error) => console.log(error)
    );
  };

  // Menu section starts
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //Menu section ends
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  function handleFlyerChange(event) {
    setFlyer(event.target.files[0]);
  }

  function handleFlyerUpload() {
    const data = new FormData();
    data.append("files", flyer);
    ClinicDataService.uploadFlyer(clinicId, data).then(
      (response) => {
        populateFlyers();
        displayMessage("The new flyer has been successfully uploaded.");
      },
      (error) => console.log(error)
    );
  }

  const deleteFlyer = (flyerId) => (event) => {
    if (window.confirm("Do you really want to unassign this flyer?")) {
      ClinicDataService.deleteFlyer(clinicId, flyerId).then(
        (response) => {
          populateFlyers();
          displayMessage("The flyer has been successfully deleted.");
        },
        (error) => console.log(error)
      );
    }
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* <Grid container style={{ backgroundColor: '#F0F4FA' }}>
          <Grid item md={8}> */}
        {/* <FormControl className={classes.formControl}>
              <InputLabel className={classes.inputLabel} id="category-label">Doctor</InputLabel>
              <Select
                id="category"
                name="category"
                label="Category"
                variant="outlined"
                value={"00"}
              >
                <MenuItem key="1" value="1">
                  Doctor name
              </MenuItem>
              </Select>
            </FormControl> */}

        {/* <Button classes={{ root: classes.borderRadius, }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              endIcon={<ArrowDropDownSharpIcon />}
              onClick={handleClick}>
              Doctor
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Doctor</MenuItem>
              <MenuItem onClick={handleClose}>Patient</MenuItem>
              <MenuItem onClick={handleClose}>Clinic</MenuItem>
            </Menu>

            <div className={classes.search}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.searchIcon}>
              <SchIcon />
            </div> */}
        {/* <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Search</InputLabel>
            <OutlinedInput
              id="searchBox"
              name="searchBox"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl> */}

        {/* </Grid> */}

        {/* </Grid> */}
        <SearchBar name="clinic" openNewIssue={props.openNewIssue} />
        <Divider />
        <Container>
          <Grid container style={{ marginTop: 50, height: 200 }} spacing={1}>
            <Grid item md={4} style={{ height: "100%" }}>
              <Card style={{ height: "100%" }}>
                <CardContent>
                  <Image src={clinic.photoUrl} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={7} style={{ height: "100%" }}>
              <Card style={{ height: "100%" }}>
                <CardContent style={{ height: "100%", marginTop: 20 }}>
                  <Grid container spacing={3}>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography>Name</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography>{clinic.name}</Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography>ID</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography>{clinic.id}</Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography>Address</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography>
                        {clinic.apartment ? clinic.apartment + "-" : ""}
                        {clinic.streetNumber +
                          " " +
                          clinic.streetName +
                          ", " +
                          clinic.city +
                          " " +
                          clinic.provinceId +
                          " " +
                          clinic.country +
                          " " +
                          clinic.postCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <AppBar
            position="static"
            color="default"
            style={{ marginTop: 35, width: "92%" }}
          >
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Introduction" {...a11yProps(0)} />
              <Tab label="Open hour" {...a11yProps(1)} />
              <Tab label="Contact" {...a11yProps(2)} />
              <Tab label="Reminder Setting" {...a11yProps(3)} />
              <Tab label="Contract" {...a11yProps(4)} />
              <Tab label="Flyer" {...a11yProps(5)} />
              <Tab label="Review Feedback" {...a11yProps(6)} />
            </Tabs>
          </AppBar>
          <TabCardPanel
            value={currentTabIndex}
            index={0}
            style={{ width: "92%" }}
          >
            <Typography variant="subtitle1">French</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={8}
              defaultValue="Default Value"
              variant="outlined"
              fullWidth
              value={frIntro}
              onChange={changeFrIntro}
            />
            <Typography variant="subtitle1">English</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={8}
              defaultValue="Default Value"
              variant="outlined"
              fullWidth
              value={enIntro}
              onChange={changeEnIntro}
            />
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 30 }}
              onClick={updateIntro}
            >
              Update
            </Button>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={1}
            style={{ width: "92%" }}
          >
            <Table style={{ marginLeft: "auto", marginRight: "auto" }}>
              <TableBody>
                {openHours.map((hour, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{hour.day}</TableCell>
                    <TableCell align="center" style={{ color: "#1A568E" }}>
                      {hour.open}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#1A568E" }}>
                      {hour.close}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={deleteOpenHour(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <InputLabel id="demo-simple-select-helper-label">
                      Day
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={newHour.day}
                      onChange={handleHourChange("day")}
                    >
                      <MenuItem value={"Monday"}>Monday</MenuItem>
                      <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                      <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                      <MenuItem value={"Thursday"}>Thursday</MenuItem>
                      <MenuItem value={"Friday"}>Friday</MenuItem>
                      <MenuItem value={"Saturday"}>Saturday</MenuItem>
                      <MenuItem value={"Sunday"}>Sunday</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <InputLabel id="demo-simple-select-helper-label">
                      Open at:
                    </InputLabel>
                    <Input
                      value={newHour.open}
                      style={{ marginRight: 30 }}
                      onChange={handleHourChange("open")}
                    />
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={newHour.openAt}
                      onChange={handleHourChange("openAt")}
                    >
                      <MenuItem value={"AM"}>AM</MenuItem>
                      <MenuItem value={"PM"}>PM</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <InputLabel id="demo-simple-select-helper-label">
                      Close at:
                    </InputLabel>
                    <Input
                      value={newHour.close}
                      style={{ marginRight: 30 }}
                      onChange={handleHourChange("close")}
                    />
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={newHour.closeAt}
                      onChange={handleHourChange("closeAt")}
                    >
                      <MenuItem value={"AM"}>AM</MenuItem>
                      <MenuItem value={"PM"}>PM</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={addOpenHour}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={2}
            style={{ width: "92%" }}
          >
            <form>
              {contacts.map((contact, index) => (
                <React.Fragment>
                  {1 == index % 2 ? (
                    <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                  ) : (
                    ""
                  )}
                  <Grid container spacing={5} key={contact.id}>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Name</InputLabel>
                        <Input
                          value={contact.name}
                          onChange={updateContactInfo(contact.id, "name")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Email</InputLabel>
                        <Input
                          value={contact.email}
                          onChange={updateContactInfo(contact.id, "email")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Department</InputLabel>
                        <Input
                          value={contact.department}
                          onChange={updateContactInfo(contact.id, "department")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Phone</InputLabel>
                        <Input
                          value={contact.phone}
                          onChange={updateContactInfo(contact.id, "phone")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Fax</InputLabel>
                        <Input
                          value={contact.fax}
                          onChange={updateContactInfo(contact.id, "fax")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        size="small"
                        onClick={update(contact.id)}
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Grid>
                    <Grid item md={1}>
                      <IconButton onClick={deleteContact(contact.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </form>
            <Divider />
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel>Name</InputLabel>
                  <Input
                    value={editContact.name}
                    onChange={changeContactInfo("name")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel>Email</InputLabel>
                  <Input
                    value={editContact.email}
                    onChange={changeContactInfo("email")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel>Department</InputLabel>
                  <Input
                    value={editContact.department}
                    onChange={changeContactInfo("department")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel>Phone</InputLabel>
                  <Input
                    value={editContact.phone}
                    onChange={changeContactInfo("phone")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel>Fax</InputLabel>
                  <Input
                    value={editContact.fax}
                    onChange={changeContactInfo("fax")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addContact}
                >
                  Add
                </Button>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={3}
            style={{ width: "92%" }}
          >
            <Table>
              <TableBody>
                {reminders.map((reminder, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="right"
                      style={{
                        font: "normal normal 600 16px/9px Montserrat",
                        color: "#1A568E",
                      }}
                    >
                      {reminder.type.toUpperCase() == "PHONE" ? (
                        <PhoneCallIcon />
                      ) : reminder.type.toUpperCase() == "EMAIL" ? (
                        <EmailIcon />
                      ) : (
                        <PhoneIcon />
                      )}
                      &nbsp; {reminder.type}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        disableUnderline={true}
                        style={{
                          border: "0.5px solid #707070",
                          borderRadius: 5,
                        }}
                        InputProps={{
                          className: classes.centerInput,
                        }}
                        value={reminder.days}
                        variant="filled"
                        className={classes.days}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#1A568E", textAlign: "left" }}
                    >
                      day(s) before appointment
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={deleteReminder(index)}>
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        className="selected-item"
                        value={reminderType}
                        displayEmpty
                        width="fullWidth"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        onChange={changeReminderType}
                      >
                        <MenuItem value={0} className="icon-item">
                          <PhoneInTalkIcon />
                          <span>Phone</span>
                        </MenuItem>
                        <MenuItem value={1} className="icon-item">
                          <MailOutlineIcon />
                          <span>Email</span>
                        </MenuItem>
                        <MenuItem value={2} className="icon-item">
                          <SmsIcon />
                          <span>SMS</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={reminderDay}
                      onChange={changeReminderDay}
                      style={{
                        border: "0.5px solid #707070",
                        borderRadius: 5,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "#1A568E", textAlign: "left" }}
                  >
                    day(s) before appointment
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addReminder}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={4}
            style={{ width: "92%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Contract ID</TableCell>
                  <TableCell>Sign Date</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts.map((contract) => (
                  <TableRow key={contract.id}>
                    <TableCell>{contract.contractNo}</TableCell>
                    <TableCell>{contract.signingDate}</TableCell>
                    <TableCell>{contract.startDate}</TableCell>
                    <TableCell>{contract.endDate}</TableCell>
                    <TableCell>{contract.uploadDate}</TableCell>
                    <TableCell>
                      <Button
                        style={{
                          backgroundColor: "#3553B21A",
                          border: "1px solid #3553B2",
                          borderRadius: 5,
                          color: "#3553B2",
                          minWidth: 80,
                          textAlign: "center",
                          padding: 5,
                        }}
                        onClick={downloadContract(contract.attachmentId)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={5}
            style={{ width: "92%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flyer ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flyers.map((flyer) => (
                  <TableRow key={flyer.id}>
                    <TableCell>{flyer.id}</TableCell>
                    <TableCell>{flyer.attachmentName}</TableCell>
                    <TableCell>
                      <Button
                        style={{
                          backgroundColor: "#3553B21A",
                          border: "1px solid #3553B2",
                          borderRadius: 5,
                          color: "#3553B2",
                          minWidth: 80,
                          textAlign: "center",
                          padding: 5,
                        }}
                        role="link"
                        onClick={() =>
                          openInNewTab(flyer.attachment.attachmentLocation)
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={deleteFlyer(flyer.attachmentId)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <TextField type="file" onChange={handleFlyerChange} />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleFlyerUpload}
                    >
                      Upload
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={6}
            style={{ width: "92%" }}
          >
            <Table style={{ marginLeft: "auto", marginRight: "auto" }}>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Feedback</TableCell>
                  <TableCell align="center">User Name</TableCell>
                  <TableCell align="center">Created Date</TableCell>
                </TableRow>
                {feedbackList.map((feedback, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{feedback.message}</TableCell>
                    <TableCell align="center" style={{ color: "#1A568E" }}>
                      {feedback.firstName} {feedback.lastName}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#1A568E" }}>
                      {feedback.createdDate}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabCardPanel>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default ClinicInfo;
