// import axios from "axios";
import axios from "../api/axios";
import { BASE_URL } from "./AuthService";

class ClinicDataService {
  updateClinicInfo(clinic) {
    return axios.put(`${BASE_URL}/clinics/${clinic.id}`, clinic);
  }

  findAllClinicCategories(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/categories`);
  }

  findDoctorsByClinicId(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/doctors`);
  }

  findAllAdminClinics(accountId) {
    return axios.get(`${BASE_URL}/admin/clinics/${accountId}/allClinics`);
  }

  findClinicById(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}`);
  }

  findOpenHoursById(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/openHours`);
  }

  updateOpenHours(clinicId, openHours) {
    return axios.put(`${BASE_URL}/clinics/${clinicId}/openHours`, openHours);
  }

  findAllClinicContacts(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/contacts`);
  }

  searchPatient(clinicId, patient) {
    return axios.post(
      `${BASE_URL}/clinics/${clinicId}/patients/searchPatients?page=0&size=0`,
      patient
    );
  }

  updateClinicContact(contact) {
    return axios.put(`${BASE_URL}/cliniccontacts/${contact.id}`, contact);
  }

  createClinicContact(clinicId, contact) {
    return axios.post(`${BASE_URL}/clinics/${clinicId}/contacts`, contact);
  }

  deleteClinicContact(contactId) {
    return axios.delete(`${BASE_URL}/cliniccontacts/${contactId}`);
  }

  getClinicContracts(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/allContracts`);
  }

  getContractFile(contractId) {
    return axios.get(`${BASE_URL}/download/attachments/${contractId}`, {
      responseType: "blob",
    });
  }

  getDefaultReminders(clinicId) {
    return axios.get(`${BASE_URL}/ClinicReminder/${clinicId}`);
  }

  updateDefaultReminders(reminderData) {
    return axios.post(`${BASE_URL}/ClinicReminder/save`, reminderData);
  }

  findAllCategories() {
    return axios.get(`${BASE_URL}/categories`);
  }

  findCategoryTypes(categoryId) {
    return axios.get(`${BASE_URL}/categories/${categoryId}/types`);
  }

  createIssue(issue) {
    return axios.post(`${BASE_URL}/reportIssues`, issue);
  }

  getIssue(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/reportIssues`);
  }

  getIssueFiles(issueId) {
    return axios.get(`${BASE_URL}/reportIssues/${issueId}/attachment`);
  }

  deleteIssue(issueId) {
    return axios.delete(`${BASE_URL}/reportIssues/${issueId}`);
  }

  deleteIssueFile(issueId, attachmentId) {
    return axios.delete(
      `${BASE_URL}/reportIssues/${issueId}/attachment/${attachmentId}`
    );
  }

  uploadIssueFile(issueId, file) {
    return axios.post(
      `${BASE_URL}/reportIssues/${issueId}/multipleFilesUpload`,
      file,
      {}
    );
  }

  getIssueReplies(issueId) {
    return axios.get(`${BASE_URL}/reportIssues/${issueId}/reply`);
  }

  saveReply(reply) {
    return axios.post(`${BASE_URL}/reportIssues/reply`, reply);
  }

  getFeedback(clinicId) {
    return axios.get(`${BASE_URL}/feedback/clinics/${clinicId}`);
  }

  getFlyers(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/attachment/type/flyer`);
  }

  uploadFlyer(clinicId, flyer) {
    return axios.post(
      `${BASE_URL}/clinics/${clinicId}/multipleFilesUpload/type/flyer`,
      flyer,
      {}
    );
  }

  deleteFlyer(clinicId, attachmentId) {
    return axios.delete(
      `${BASE_URL}/clinics/${clinicId}/attachments/${attachmentId}`
    );
  }

  getAccountInfo(accountId) {
    return axios.get(`${BASE_URL}/accounts/${accountId}`);
  }

  updateAccount(account) {
    return axios.put(`${BASE_URL}/accounts`, account);
  }

  updatePassword(pwCombo) {
    return axios.post(`${BASE_URL}/changepassword`, pwCombo);
  }

  sendQuickMsg(msgInfo) {
    console.log("msgInfo", msgInfo);
    let subPath = "";
    let data = {};
    switch (msgInfo.type) {
      case "1":
        subPath = "sms";
        data = {
          originNumber: "+12049000519",
          mobileNumber: "+1" + msgInfo.phone,
          message: msgInfo.message,
        };
        break;
      case "2":
        subPath = "email";
        data = {
          from: "contactus@cliniplus.net",
          to: [`${msgInfo.emailAddress}`],
          subject: msgInfo.emailTitle,
          plainContent: msgInfo.htmlContent,
          htmlContent: msgInfo.message,
        };
        break;
      case "3":
        subPath = "voice";
        data = {
          lang: msgInfo.language,
          name: "",
          phoneNumber: "+1" + msgInfo.phone,
          message: msgInfo.message,
        };
        break;
      default:
        subPath = "voice";
        break;
    }
    console.log("data for send: ", data);
    return axios.post(`${BASE_URL}/${subPath}`, data);
  }
}

export default new ClinicDataService();
