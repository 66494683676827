import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import ClinicDataService from "../services/ClinicDataService";
import AppointmentDataService from "../services/AppointmentDataService";
import ExistedApps from "./ExistedApps";
import { useAppContext } from "../services/authContext";
import NewApp from "./NewApp";

function Appointment(props) {
  const { clinicId } = useAppContext();
  const [currentDay, setCurrentDay] = useState(moment());
  const [dayTabs, setDayTabs] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [clinicCategories, setClinicCategories] = useState([]);
  const [clinicDoctors, setClinicDoctors] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [appList, setAppList] = useState([]);

  const [newApp, setNewApp] = useState(false);

  useEffect(() => {
    changeDayTabs(currentDay);
    populateClinicCategories(currentDay);
  }, []);

  function populateDoctorsAndApps(categoryList, targetDay) {
    ClinicDataService.findDoctorsByClinicId(clinicId).then(
      (response) => {
        setClinicDoctors(
          response.data.sort((first, second) => first.id - second.id)
        );
        if (response.data.length > 0) {
          AppointmentDataService.findAppsByDoctors(
            response.data.map((doctor) => doctor.id).join(","),
            clinicId,
            targetDay
              ? targetDay.clone().day(0).format("YYYY-MM-DD")
              : currentDay.clone().day(0).format("YYYY-MM-DD"),
            targetDay
              ? targetDay.clone().day(6).add(1, "day").format("YYYY-MM-DD")
              : currentDay.clone().day(6).add(1, "day").format("YYYY-MM-DD")
          ).then((appResponse) =>
            transfer2ReservedApps(appResponse, categoryList, response.data)
          );
        } else {
          setAppList([]);
          setAppointments([]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function transfer2ReservedApps(response, categoryList, doctorList) {
    let result = [];
    let tmpAppList = [];
    for (let i in response.data) {
      let dayCategories = [];
      categoryList.forEach((category) => {
        let doctorAppointments = [];
        doctorList.forEach((doctor) => {
          const { categoryDescriptions, ...doctorWithoutCategory } = doctor;
          doctorAppointments.push({
            doctor: doctorWithoutCategory,
            appointments: [],
          });
        });
        if (category.id !== "00") {
          dayCategories.push({
            category: category,
            doctors: [...doctorAppointments],
          });
        }
      });
      for (let j in response.data[i]) {
        response.data[i][j].appointments.forEach((element) => {
          let categoryIndex = dayCategories.findIndex(
            (dayCategory) => dayCategory.category.id === element.categoryId
          );
          if (categoryIndex !== -1) {
            let categoryName = dayCategories[categoryIndex].category.name;
            let doctorIndex = dayCategories[categoryIndex].doctors.findIndex(
              (doctorApp) => element.doctorId === doctorApp.doctor.id
            );
            if (doctorIndex !== -1) {
              let appedDoctor =
                dayCategories[categoryIndex].doctors[doctorIndex].doctor;
              dayCategories[categoryIndex].doctors[
                doctorIndex
              ].appointments.push({ ...element });
              tmpAppList.push({
                appointmentId: element.appointmentId,
                doctor:
                  "Dr. " + appedDoctor.firstName + " " + appedDoctor.lastName,
                category: categoryName,
                patientName: element.patientName,
                type: JSON.parse(element.treatmentType).en,
                date: element.appointmentDate,
                startTime: element.startTime.slice(0, 5),
                status: (
                  element.status[0].toUpperCase() +
                  element.status.substr(1).toLowerCase()
                ).replace("_", " "),
              });
            } else {
              console.log(element.doctorId);
            }
          } else {
            console.log(element.categoryId);
          }
        });
      }
      result.push([...dayCategories.slice()]);
    }
    let trimmedResult = result.map((dayCategory) => {
      dayCategory.forEach((day) => {
        const appointedDocs = day.doctors.filter(
          (element) => element.appointments.length > 0
        );
        appointedDocs.sort();
        day.doctors = appointedDocs;
      });
      return [...dayCategory.filter((day) => day.doctors.length > 0)];
    });
    setAppList([...tmpAppList]);
    console.log(trimmedResult);
    setAppointments([...trimmedResult.slice()]);
  }

  function populateClinicCategories(date) {
    ClinicDataService.findAllClinicCategories(clinicId).then(
      (response) => {
        let tempCategories = [];
        response.data.forEach((rawCategory) => {
          if (1 == rawCategory.isLeaf) {
            let description = JSON.parse(rawCategory.description);
            tempCategories.push({
              id: rawCategory.id,
              name: description.en,
            });
          }
        });
        setClinicCategories([...tempCategories]);
        populateDoctorsAndApps(tempCategories, date);
        //setSearchCat(tempCategories[0].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function changeDayTabs() {
    let tmpTabs = [];
    for (let i = 0; i < 7; i++) {
      tmpTabs.push(currentDay.clone().day(i).format("ddd D/M"));
    }
    setDayTabs(tmpTabs);
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function openNewApp() {
    setNewApp(true);
  }

  function closeNewApp(date) {
    if (date) {
      setCurrentDay(moment(date));
    }
    populateClinicCategories(moment(date));
    setNewApp(false);
  }

  function refreshAppList() {
    populateDoctorsAndApps(clinicCategories, currentDay);
  }

  function nextWeek() {
    const targetDay = currentDay.clone().add(7, "days");
    setCurrentDay(targetDay);
    populateDoctorsAndApps(clinicCategories, targetDay);
    changeDayTabs(targetDay);
  }

  function previousWeek() {
    const targetDay = currentDay.clone().subtract(7, "days");
    setCurrentDay(targetDay);
    populateDoctorsAndApps(clinicCategories, targetDay);
    changeDayTabs(targetDay);
  }

  function changeDayTabs(toDay) {
    let tmpTabs = [];
    for (let i = 0; i < 7; i++) {
      tmpTabs.push(toDay.clone().day(i).format("ddd D/M"));
    }
    setDayTabs([...tmpTabs.slice()]);
  }

  return (
    <Box width={1}>
      {newApp ? (
        <NewApp
          clinicCategories={clinicCategories}
          clinicDoctors={clinicDoctors}
          closeNewApp={closeNewApp}
        />
      ) : (
        <ExistedApps
          clinicCategories={clinicCategories}
          currentDay={currentDay}
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
          dayTabs={dayTabs}
          appointments={appointments}
          openNewApp={openNewApp}
          nextWeek={nextWeek}
          previousWeek={previousWeek}
          appList={appList}
          refreshAppList={refreshAppList}
        />
      )}
    </Box>
  );
}

export default Appointment;
