import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import {
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Button,
  TextField,
  // Container,
  // Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../services/AuthService";
import "./LoginStyle.css";
import { ReactComponent as LogoLoginIcon } from "../static/images/SVGs/logoLogin.svg";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import { useAppContext } from "../services/authContext";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"cpyright © "}
//       <Link color="inherit" href="http://medinetwork.surge.sh">
//         Medinetwork
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ResetPW(props) {
  const { displayMessage, displayAlert } = useAppContext();
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [cfpassword, setCfPassword] = useState("");
  const [vcode, setVcode] = useState("");

  const handlePWChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCFPWChange = (event) => {
    setCfPassword(event.target.value);
  };

  const handleVcodeChange = (event) => {
    setVcode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === cfpassword) {
      AuthService.resetpw({
        email: "administrator@cliniplus.net",
        roleId: "3",
        verificationCode: vcode,
        password: password,
      }).then(
        (response) => {
          displayMessage(
            "You password has been reset successfully. The page is going to be redirect to sign in page."
          );
          setTimeout(() => {
            props.history.push("/login");
          }, 2000);
        },
        (error) => console.log(error)
      );
    } else {
      displayAlert("The passwords do not match");
    }
  };

  return (
    <div className="login-container">
      <CssBaseline />
      <div className="left-side d-none d-sm-block">
        <div className="icon-line">
          <LogoLoginIcon />
        </div>
        <div className="image-content">
          <img src="/assets/images/loginContent.svg" alt="login content" />
        </div>
      </div>
      <div className="right-side">
        <div className="paper">
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          {/* <Typography component="h1" variant="h5">
            Sign in 
          </Typography> */}
          <form className="form" noValidate onSubmit={handleSubmit}>
            <div className="form-title">Reset your Password</div>
            <TextField
              required
              variant="filled"
              margin="normal"
              fullWidth
              name="vcode"
              type="vcode"
              id="vcode"
              autoComplete="current-password"
              value={vcode}
              onChange={handleVcodeChange}
              label="Verification Code"
            />
            <TextField
              required
              variant="filled"
              id="password"
              name="password"
              autoComplete="password"
              autoFocus
              type="password"
              value={password}
              onChange={handlePWChange}
              label="Password"
            />
            <TextField
              required
              variant="filled"
              margin="normal"
              fullWidth
              name="cfpassword"
              type="password"
              id="cfpassword"
              autoComplete="current-password"
              value={cfpassword}
              onChange={handleCFPWChange}
              label="Confirm Password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="submit"
            >
              Reset
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
