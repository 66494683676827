// import axios from "axios";
import axios from "../api/axios";
import { BASE_URL } from "./AuthService";

class AppointmentDataService {
  findAppsByDoctors(doctorList, clinicId, startDate, endDate) {
    return axios.get(
      `${BASE_URL}/clinics/${clinicId}/doctors/${doctorList}/search/appointments/${startDate}/${endDate}`
    );
  }

  findAvailableApp(clinicId, doctorIds, categoryId, treatmentId, startDate) {
    return axios.get(
      `${BASE_URL}/clinics/${clinicId}/doctors/${doctorIds}/search/dailySchedule/${categoryId}/${treatmentId}/${startDate}?currentWeekOnly=false`
    );
  }

  getAppDetailsById(appId) {
    return axios.get(`${BASE_URL}/appointments/infos/${appId}`);
  }

  saveNewApp(newApp) {
    return axios.post(`${BASE_URL}/appointments/saveAppointment`, newApp);
  }

  saveReminders(reminders, appointmentId) {
    return axios.post(
      `${BASE_URL}/appointments/reminders/${appointmentId}/en`,
      reminders
    );
  }

  removeReminder(reminderId) {
    return axios.delete(`${BASE_URL}/appointments/reminders?ids=${reminderId}`);
  }

  getReminders(appointmentId) {
    return axios.get(`${BASE_URL}/appointments/reminders/${appointmentId}`);
  }

  getPrepareComment(appointmentId) {
    return axios.get(`${BASE_URL}/appointments/comment/${appointmentId}`);
  }

  savePrepareComment(appointmentId, comment) {
    return axios.post(`${BASE_URL}/appointments/comment/${appointmentId}`, {
      body: comment,
    });
  }

  saveRoom(appointmentId, roomNumber) {
    return axios.post(
      `${BASE_URL}/appointments/room/${appointmentId}?room=${roomNumber}`
    );
  }

  updateStatus(appointmentId, status) {
    return axios.post(
      `${BASE_URL}/appointments/updateStatus/${appointmentId}/${status}`
    );
  }
}

export default new AppointmentDataService();
