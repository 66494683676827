import React, { useState } from "react";
import moment from "moment";
import {
  withStyles,
  Divider,
  AppBar,
  Tabs,
  Tab,
  Input,
  Container,
  Typography,
  IconButton,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Image from "material-ui-image";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Delete } from "@material-ui/icons";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PatientDataService from "../services/PatientDataService";
import AppQuestion from "./AppQuestion";
import { Link } from "react-router-dom";
import { useAppContext } from "../services/authContext";
import Avatar from "@material-ui/core/Avatar";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: "rgba(0, 0, 0, .03)",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    // minHeight: 56,
    // "&$expanded": {
    //   minHeight: 56,
    // },
  },
  content: {
    // "&$expanded": {
    //   margin: "12px 0",
    // },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </Container>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function PatientDetail(props) {
  const { displayMessage } = useAppContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [msg, setMsg] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [photo, setPhoto] = useState("");

  const assignDoc = (patientId, doctorId) => (event) => {
    if (
      window.confirm(
        "Do you really want to unassign this patient from the doctor?"
      )
    ) {
      PatientDataService.unassignPatientFromDoc(patientId, doctorId).then(
        (response) => {
          props.getDocs(props.patient.id);
          displayMessage("The patient has been unassigned from the doctor");
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  function handlePhotoChange(event) {
    setPhoto(event.target.files[0]);
  }

  function handleUploadPhoto() {
    const data = new FormData();
    data.append("file", photo);
    PatientDataService.uploadPhoto(props.patient.id, data).then(
      (response) => {
        props.buildPatient(props.patient.id);
        displayMessage("The patient's photo has been successfully uploaded.");
        console.log(response);
      },
      (error) => console.log(error)
    );
  }

  function changeDoctor(event) {
    console.log(event.target.value);
    setSelectedDoctor(event.target.value);
  }

  function changeMsg(event) {
    setMsg(event.target.value);
  }

  function saveMsg() {
    if (!selectedDoctor) {
      window.alert("Selected doctor is required.");
      return;
    }
    PatientDataService.saveNote({
      doctorId: selectedDoctor,
      patientId: props.patient.id,
      message: msg,
    }).then(
      (response) => {
        props.getNotes(props.patient.id);
        displayMessage("The message has been successfully saved.");
        setMsg("");
        setSelectedDoctor("");
      },
      (error) => console.log(error)
    );
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function dumbQuestionsChange() {
    console.log("dumbQuestionsChange");
  }

  const deleteFile = (attachmentId) => (event) => {
    if (window.confirm("Do you realy want to delete this medical file?")) {
      PatientDataService.deletePatientFile(props.patient.id, attachmentId).then(
        (response) => {
          props.getMedicalFiles(props.patient.id);
          displayMessage("The medical file has been successfully deleted.");
        },
        (error) => console.log(error)
      );
    }
  };

  function handleFileChange(event) {
    setUploadFile(event.target.files[0]);
  }

  function handleUploadFile() {
    const data = new FormData();
    data.append("file", uploadFile);
    console.log(data);
    PatientDataService.uploadFile(props.patient.id, data).then(
      (response) => {
        props.getMedicalFiles(props.patient.id);
        displayMessage("The file has been successfully uploaded");
      },
      (error) => console.log(error)
    );
  }

  return (
    <React.Fragment>
      <Typography style={{ position: "relative" }}>
        <IconButton color="primary" onClick={props.showPatientList}>
          <ArrowBackIosIcon fontSize="large" />
          Back
        </IconButton>
        <span
          style={{
            position: "absolute",
            right: "36px",
            top: "20px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {props.patient.firstName} {props.patient.lastName}
        </span>
      </Typography>
      <Container>
        <AppBar position="static" color="default" style={{ marginTop: 10 }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Information" {...a11yProps(0)} />
            <Tab label="Doctors" {...a11yProps(1)} />
            <Tab label="Medical Files" {...a11yProps(2)} />
            <Tab label="Notes" {...a11yProps(3)} />
            <Tab label="Messages" {...a11yProps(3)} />
            <Tab label="Survey" {...a11yProps(4)} />
            <Tab label="Appointments" {...a11yProps(5)} />
          </Tabs>
        </AppBar>

        <TabPanel value={currentTabIndex} index={0} className="info-container">
          <Typography variant="h6" className="content-title">
            General Information
          </Typography>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell rowSpan={3} >
                  <TabPanel value={currentTabIndex} index={0} className="info-container">
                    <Grid container >
                      <Grid item md={6}>
                        {/* Display patient photo */}
                        <Card
                          style={{
                            marginTop: "16px",
                            height: "100%",
                            marginRight: "20px",
                          }}
                        >
                          <Image src={props.patient.photoUrl} />
                        </Card>
                      </Grid>
                      <Grid item md={6}>
                        {/* Adjusted layout */}
                        <FormControl
                          style={{
                            margin: "40px auto 0",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        >
                          <TextField type="file" onChange={handlePhotoChange} />
                        </FormControl>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 20, width: "100%", maxWidth: 200 }} // Adjusted button width
                          onClick={handleUploadPhoto}
                        >
                          Upload
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    First Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.patient.firstName}
                    onChange={props.changePatientInfo("firstName")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Middle Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.patient.middleName}
                    onChange={props.changePatientInfo("middleName")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Family Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    value={props.patient.lastName}
                    onChange={props.changePatientInfo("lastName")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Date of birth
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    value={props.patient.dateOfBirth}
                    onChange={props.changePatientInfo("mediCardNumber")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Age
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    value={moment().diff(props.patient.dateOfBirth, "years")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Gender
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    value={
                      props.patient.gender === '1'
                        ? "Male"
                        : props.patient.gender === '2'
                        ? "Female"
                        : "Don't want to tell"
                    }
                    onChange={props.changePatientInfo("gender")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Medical Card Number
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField value={props.patient.mediCardNumber} disabled />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Expiration
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <TextField value={props.patient.mediCardExp} disabled />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" className="content-subtitle">
                    Preferred language
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <Select
                    labelId="lang-select"
                    id="lang-select"
                    value={props.patient.lang}
                    onChange={props.changePatientInfo("lang")}
                  >
                    <MenuItem value={0}>English</MenuItem>
                    <MenuItem value={1}>Francais</MenuItem>
                    <MenuItem value={2}>中文</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6" className="content-title">
            Contact Information
          </Typography>
          <Divider style={{ marginBottom: "16px" }} />
          <Grid container spacing={3} className="content-section">
            {props.patient.contactPhoneType === "CELL" ||
            props.patient.contactPhoneType === "SelfRegistered" ? (
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={2}>
                    <Typography
                      variant="subtitle1"
                      className="content-subtitle"
                    >
                      Mobile Phone
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      value={props.patient.contactPhone}
                      onChange={props.changePatientInfo("contactPhone")}
                      disabled={
                        props.patient.contactPhoneType === "SelfRegistered"
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.patient.contactPhoneType === "CELL"}
                          onChange={props.changePhoneType("LAND")}
                          name="mobilephone"
                          color="primary"
                          disabled={
                            props.patient.contactPhoneType === "SelfRegistered"
                          }
                        />
                      }
                      label="Mobile"
                    />
                  </Grid>
                  <Grid item md={6}></Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={2}>
                    <Typography
                      variant="subtitle1"
                      className="content-subtitle"
                    >
                      Landline
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      value={props.patient.contactPhone}
                      onChange={props.changePatientInfo("contactPhone")}
                      style={{ marginRight: 30 }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Typography
                      variant="subtitle11"
                      className="content-subtitle"
                    >
                      Ext
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      value={props.patient.contactPhoneExt}
                      onChange={props.changePatientInfo("contactPhoneExt")}
                      style={{ marginRight: 30 }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.patient.contactPhoneType === "CELL"}
                          onChange={props.changePhoneType("CELL")}
                          name="Mobile"
                          color="primary"
                        />
                      }
                      label="Mobile"
                    />
                  </Grid>
                  <Grid item md={3}></Grid>
                </Grid>
              </Grid>
            )}
            <Grid item md={2}>
              <Typography variant="subtitle1" className="content-subtitle">
                Email
              </Typography>
            </Grid>
            <Grid item md={3}>
              <TextField
                value={props.patient.email}
                onChange={props.changePatientInfo("email")}
                fullWidth
              />
            </Grid>
            <Grid item md={7}></Grid>
            <Grid item md={2}>
              <Typography variant="subtitle1" className="content-subtitle">
                Street Number
              </Typography>
            </Grid>
            <Grid item md={2}>
              <TextField
                value={props.patient.streetNumber}
                onChange={props.changePatientInfo("streetNumber")}
                fullWidth
              />
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle1" className="content-subtitle">
                Street Name
              </Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                value={props.patient.streetName}
                onChange={props.changePatientInfo("streetName")}
                fullWidth
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" className="content-subtitle">
                Apt
              </Typography>
            </Grid>
            <Grid item md={1}>
              <TextField
                value={props.patient.apartment}
                onChange={props.changePatientInfo("apartment")}
                fullWidth
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" className="content-subtitle">
                City
              </Typography>
            </Grid>
            <Grid item md={2}>
              <TextField
                value={props.patient.city}
                onChange={props.changePatientInfo("city")}
                fullWidth
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" className="content-subtitle">
                Country
              </Typography>
            </Grid>
            <Grid item md={2}>
              <TextField
                value={props.patient.country}
                onChange={props.changePatientInfo("country")}
                fullWidth
              />
            </Grid>
            <Grid item md={2}>
              <Typography variant="subtitle1" className="content-subtitle">
                Postal Code
              </Typography>
            </Grid>
            <Grid item md={2}>
              <TextField
                value={props.patient.postcode}
                onChange={props.changePatientInfo("postcode")}
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider style={{ margin: 30 }} />
          <Grid container spacing={3}>
            <Grid item md={6}></Grid>
            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={props.updatePatient}
              >
                Update
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button variant="contained">Reset</Button>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={currentTabIndex} index={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography color="primary" className="content-subtitle">
                  Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className="content-subtitle">
                  Medical Insurance Card
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className="content-subtitle">
                  Contact
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.doctors.map((doctor) => (
                <TableRow key={doctor.id}>
                  <TableCell>
                    <Avatar src={doctor.photoUrl} />
                  </TableCell>
                  <TableCell>
                    {"Dr. " +
                      doctor.firstName +
                      " " +
                      (doctor.middleName ? doctor.middleName + " " : "") +
                      doctor.lastName}
                  </TableCell>
                  <TableCell>{doctor.mediCardNumber}</TableCell>
                  <TableCell>{doctor.email}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={assignDoc(props.patient.id, doctor.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={currentTabIndex} index={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="primary" className="content-subtitle">
                  Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className="content-subtitle">
                  Uploaded By
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="primary" className="content-subtitle">
                  Uploaded Time
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.attachments.map((attachment) => (
                <TableRow key={attachment.attachmentId}>
                  <TableCell>
                    <Link
                      to={{ pathname: attachment.attachmentLocation }}
                      target="_blank"
                    >
                      {attachment.attachmentKey}
                    </Link>
                  </TableCell>
                  <TableCell>{attachment.creator}</TableCell>
                  <TableCell>{attachment.createdDate}</TableCell>
                  <TableCell>
                    <IconButton onClick={deleteFile(attachment.attachmentId)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key="xxx">
                <TableCell>
                  <Input type="file" onChange={handleFileChange} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadFile}
                  >
                    Upload
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={3}
          className="message-container"
        >
          <Card>
            <CardContent>
              <FormControl>
                <Typography variant="subtitle2">Doctor</Typography>
                <Select
                  style={{ width: 300, marginRight: 50 }}
                  onChange={changeDoctor}
                  value={selectedDoctor}
                >
                  {props.doctors.map((doctor) => (
                    <MenuItem value={doctor.id}>
                      {"Dr. " +
                        doctor.firstName +
                        " " +
                        (doctor.middleName ? doctor.middleName + " " : "") +
                        doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <TextField
                  rows={8}
                  variant="outlined"
                  multiline
                  size="medium"
                  style={{ width: 600 }}
                  value={msg}
                  onChange={changeMsg}
                />
              </FormControl>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={saveMsg}
                style={{ marginLeft: 770, marginTop: 30 }}
              >
                Save
              </Button>
            </CardActions>
          </Card>
          {props.notes.map((note) => (
            <Accordion square key={note.id} className="patient-msg-item">
              <AccordionSummary
                className="expand-icon"
                expandIcon={<ExpandMoreIcon />}
              >
                {/* <Typography> */}
                <div className="item-title">
                  <div className="title title-from">
                    <div className="label">From</div>
                    <div className="content">Dr. {note.firstName}</div>
                  </div>
                  <div className="title title-date">
                    <div className="label">Date</div>
                    <div className="content">{note.createdDate}</div>
                  </div>
                </div>

                {/* From: Dr. {note.firstName + " " + note.lastName} Date:{" "}
                  {note.createdDate} */}
                {/* </Typography> */}
              </AccordionSummary>
              <AccordionDetails className="item-content">
                <Container>{note.message}</Container>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={4}
          className="message-container"
        >
          <Card>
            <CardContent>
              <FormControl>
                <Typography variant="subtitle2">Doctor</Typography>
                <Select
                  style={{ width: 300, marginRight: 50 }}
                  onChange={changeDoctor}
                  value={selectedDoctor}
                >
                  {props.doctors.map((doctor) => (
                    <MenuItem value={doctor.id}>
                      {"Dr. " +
                        doctor.firstName +
                        " " +
                        (doctor.middleName ? doctor.middleName + " " : "") +
                        doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <TextField
                  rows={8}
                  variant="outlined"
                  multiline
                  size="medium"
                  style={{ width: 600 }}
                  value={msg}
                  onChange={changeMsg}
                />
              </FormControl>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={saveMsg}
                style={{ marginLeft: 770, marginTop: 30 }}
              >
                Save
              </Button>
            </CardActions>
          </Card>
          {props.notes.map((note) => (
            <Accordion square key={note.id} className="patient-msg-item">
              <AccordionSummary
                className="expand-icon"
                expandIcon={<ExpandMoreIcon />}
              >
                {/* <Typography> */}
                <div className="item-title">
                  <div className="title title-from">
                    <div className="label">From</div>
                    <div className="content">Dr. {note.firstName}</div>
                  </div>
                  <div className="title title-date">
                    <div className="label">Date</div>
                    <div className="content">{note.createdDate}</div>
                  </div>
                </div>

                {/* From: Dr. {note.firstName + " " + note.lastName} Date:{" "}
                  {note.createdDate} */}
                {/* </Typography> */}
              </AccordionSummary>
              <AccordionDetails className="item-content">
                <Container>{note.message}</Container>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={5}
          className="message-container"
        >
          {props.surveys.map((survey) => (
            <Accordion
              square
              key={survey.surveyId}
              className="patient-msg-item"
            >
              {/* <AccordionSummary className="expand-icon" expandIcon={<ExpandMoreIcon />}> */}
              <AccordionSummary>
                {/* <Typography> */}
                <div className="item-title">
                  <div className="title title-title survey-title">
                    <div className="label">Title</div>
                    <div className="content">{survey.title}</div>
                  </div>
                  <div className="title title-from survey-title">
                    <div className="label">From</div>
                    <div className="content">
                      Dr.&nbsp;{survey.doctorFirstName}&nbsp;
                      {survey.doctorLastName}
                    </div>
                  </div>
                  <div className="title title-date survey-title">
                    <div className="label">Date</div>
                    <div className="content">{survey.createdDate}</div>
                  </div>
                  <div className="title title-id survey-title">
                    <div className="label">ID</div>
                    <div className="content">{survey.surveyId}</div>
                  </div>
                </div>
                {/* From: Dr.{" "}
                  {survey.doctorFirstName + " " + survey.doctorLastName} Date:{" "}
                  {survey.createdDate} */}
                {/* </Typography> */}
              </AccordionSummary>
              <AccordionDetails className="item-content">
                <Container>
                  <AppQuestion
                    questions={survey.questions}
                    answers={survey.answers}
                    changeSingleAnswer={dumbQuestionsChange}
                    changeTextAnswer={dumbQuestionsChange}
                    changeMultiAnswer={dumbQuestionsChange}
                    handleFileChange={dumbQuestionsChange}
                    disabled={true}
                  />
                </Container>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
        <TabPanel
          value={currentTabIndex}
          index={6}
          className="message-container"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Doctor Name</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Treatment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.apps.map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell>
                    {appointment.appointmentDate} {appointment.startTime}
                  </TableCell>
                  <TableCell>{appointment.doctorName}</TableCell>
                  <TableCell>{appointment.patientName}</TableCell>
                  <TableCell>{appointment.clinicName}</TableCell>
                  <TableCell>
                    {appointment.apartment} {appointment.streetNumber}{" "}
                    {appointment.streetName}, {appointment.city}
                  </TableCell>
                  <TableCell>
                    {JSON.parse(appointment.categoryDescription).en}
                  </TableCell>
                  <TableCell>
                    {JSON.parse(appointment.treatmentType).en}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>
      </Container>
    </React.Fragment>
  );
}

export default PatientDetail;
