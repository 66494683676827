import React, { useState, useEffect } from "react";
import {
  Stepper,
  Paper,
  Step,
  StepLabel,
  Button,
  makeStyles,
  Container,
  Typography,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  FormControl,
  IconButton,
  Select,
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import QuestionComponent from "./QuestionComponent";
import SurveyDataService from "../services/SurveyDataService";
import "./NewSurveyStyle.css";
import { useAppContext } from "../services/authContext";

function getSteps() {
  return ["Add Title", "Add Questions", "Save as Draft"];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  button: {
    margin: theme.spacing(2),
  },
  tableCellTop: {
    verticalAlign: "top",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function NewSurvey(props) {
  const classes = useStyles();
  const { displayMessage, displayAlert, clinicId } = useAppContext();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [enTitle, setEnTitle] = useState("");
  const [frTitle, setFrTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const initialQuestion = {
    type: 1,
    context: "",
    context_fr: "",
    mandatory: false,
    options: [],
  };
  const [designQuestion, setDesignQuestion] = useState(initialQuestion);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [savedSurveyId, setSavedSurveyId] = useState("");

  const deleteQuestion = (index) => (event) => {
    if (window.confirm("Do you really want to delete this question?")) {
      let tmpQuestions = questions;
      tmpQuestions.splice(index, 1);
      setQuestions([...tmpQuestions]);
    }
  };

  const editQuestion = (index) => (event) => {
    setDesignQuestion(JSON.parse(JSON.stringify(questions[index])));
    setEditingIndex(index);
  };

  const changeMandatory = (name) => (event) => {
    setDesignQuestion({ ...designQuestion, [name]: event.target.checked });
  };

  const changeText = (name) => (event) => {
    setDesignQuestion({ ...designQuestion, [name]: event.target.value });
  };

  const changeType = (event) => {
    setDesignQuestion({ ...initialQuestion, type: event.target.value });
  };

  const addOption = () => {
    let tmpQuestion = designQuestion;
    tmpQuestion.options = [
      ...tmpQuestion.options,
      { en: "", fr: "", correctAnswer: false },
    ];
    setDesignQuestion({ ...tmpQuestion });
  };

  const changeCorrectAnswer = (index) => (event) => {
    let tmpQuestion = designQuestion;
    if (tmpQuestion.type === 2) {
      tmpQuestion.options.forEach((option) => (option.correctAnswer = false));
    }
    tmpQuestion.options[index].correctAnswer = event.target.checked;
    setDesignQuestion({ ...tmpQuestion });
  };

  const deleteOption = (index) => (event) => {
    let tmpQuestion = designQuestion;
    tmpQuestion.options.splice(index, 1);
    setDesignQuestion({ ...tmpQuestion });
  };

  const changeOption = (name, index) => (event) => {
    let tmpQuestion = JSON.parse(JSON.stringify(designQuestion));
    tmpQuestion.options[index][name] = event.target.value;
    setDesignQuestion({ ...tmpQuestion });
  };

  const saveDesignQuestion = () => {
    console.log(questions);
    if (editingIndex !== -1) {
      questions[editingIndex] = designQuestion;
    } else {
      setQuestions([...questions, designQuestion]);
    }
    setDesignQuestion(initialQuestion);
    setEditingIndex(-1);
  };

  function changeEnTitle(event) {
    setEnTitle(event.target.value);
  }

  function changeFrTitle(event) {
    setFrTitle(event.target.value);
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    if (2 === activeStep) {
      createSurvey();
    }
    setActiveStep(activeStep + 1);
  }

  const createSurvey = () => {
    let formattedQuestions = [];
    questions.map((question) => {
      let optionsStr = "[";
      if (question.options) {
        let begin = 0;
        question.options.map((element) => {
          if (0 === begin) {
            begin = 2;
          } else {
            optionsStr += ",";
          }
          optionsStr += '{"en": "' + element.en + '"';
          optionsStr += ',"fr": "' + element.fr + '"';
          optionsStr +=
            ',"correctAnswer": ' +
            (element.correctAnswer ? element.correctAnswer : false) +
            " }";
        });
      }
      optionsStr += "]";
      formattedQuestions.push({
        creatorId: localStorage.getItem("accountId"),
        context:
          '{"en":"' +
          question.context +
          '","fr":"' +
          question.context_fr +
          '"}',
        type: question.type,
        mandatory: question.mandatory,
        options: optionsStr,
      });
    });
    let survey = {
      survey: {
        creatorId: localStorage.getItem("accountId"),
        operatorId: null,
        clinicId: clinicId,
        title: enTitle,
        status: "DRAFT",
        id: null,
      },
      surveyQuestions: [...formattedQuestions],
    };
    console.log(survey);
    SurveyDataService.createSurvey(survey).then(
      (response) => {
        props.populateSurveys(0, props.rowsPerPage);
        displayMessage("The survey has been successfully created.");
        props.closeNewSurvey();
      },
      (error) => displayAlert("The survey cannot be created.")
    );
  };

  return (
    <React.Fragment>
      <Typography align="right">
        <IconButton color="primary" onClick={props.closeNewSurvey}>
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </Typography>
      <Stepper activeStep={activeStep} className="new-survey-step">
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Paper className="survey-paper">
        <form>
          <Container
            className="survey-section"
            style={{
              display: activeStep === 0 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className="step-title">
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider className="title-divider" />
            <AppBar position="static" color="default" style={{ marginTop: 10 }}>
              <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="French" {...a11yProps(0)} />
                <Tab label="English" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={currentTabIndex} index={0}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={8}
                variant="outlined"
                fullWidth
                value={frTitle}
                onChange={changeFrTitle}
              />
            </TabPanel>
            <TabPanel value={currentTabIndex} index={1}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={8}
                variant="outlined"
                fullWidth
                value={enTitle}
                onChange={changeEnTitle}
              />
            </TabPanel>
          </Container>
          <Container
            className="survey-section"
            style={{
              display: activeStep === 1 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className="step-title">
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider className="title-divider" />
            <Grid container spacing={3}>
              <Grid item md={6} style={{ padding: 30 }}>
                <Box
                  borderRight={1}
                  borderColor="grey.300"
                  style={{ padding: 10 }}
                >
                  {editingIndex !== -1 ? (
                    <Typography variant="h6">
                      You are editing question {editingIndex}
                    </Typography>
                  ) : (
                    ""
                  )}
                  <FormControl>
                    <FormControlLabel
                      value="mandatory"
                      control={
                        <Switch
                          color="primary"
                          checked={designQuestion.mandatory}
                          onChange={changeMandatory("mandatory")}
                        />
                      }
                      label="Mandatory to answer"
                      labelPlacement="start"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="question-type-label">Type</InputLabel>
                    <Select
                      labelId="question-type-label"
                      id="question-type"
                      value={designQuestion.type}
                      onChange={changeType}
                    >
                      <MenuItem value={1}>Question and Text Answer</MenuItem>
                      <MenuItem value={2}>Single Selection</MenuItem>
                      <MenuItem value={3}>Multiple Selections</MenuItem>
                      <MenuItem value={4}>Upload File</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Français"
                    margin="normal"
                    fullWidth
                    value={designQuestion.context_fr}
                    onChange={changeText("context_fr")}
                  />
                  <TextField
                    label="English"
                    margin="normal"
                    fullWidth
                    value={designQuestion.context}
                    onChange={changeText("context")}
                  />
                  {designQuestion.type === 2 || designQuestion.type === 3 ? (
                    <div>
                      <Typography variant="subtitle2" gutterBottom>
                        Answer Options:
                      </Typography>
                      <List
                        component="nav"
                        aria-label="Secondary mailbox folders"
                      >
                        {designQuestion.options.map((option, index) => (
                          <ListItem key={index}>
                            {" "}
                            <ListItemText primary={index + 1} />{" "}
                            <TextField
                              label="Français"
                              margin="normal"
                              value={option.fr}
                              fullWidth
                              className={classes.option}
                              onChange={changeOption("fr", index)}
                            />
                            <TextField
                              label="English"
                              margin="normal"
                              value={option.en}
                              fullWidth
                              className={classes.option}
                              onChange={changeOption("en", index)}
                            />
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={!designQuestion.mandatory}
                                    checked={
                                      designQuestion.options[index]
                                        .correctAnswer
                                    }
                                    color="primary"
                                    onChange={changeCorrectAnswer(index)}
                                  />
                                }
                                label="Must select"
                              />
                            </FormGroup>
                            <IconButton onClick={deleteOption(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                      <Grid container className={classes.paper}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={addOption}
                          >
                            Add Answer Option
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveDesignQuestion}
                  disabled={
                    (designQuestion.type === 3 || designQuestion.type === 2
                      ? designQuestion.options.length < 2 ||
                        designQuestion.options.find(
                          (option) => !(option.en || option.fr)
                        )
                      : false) ||
                    !(designQuestion.context_fr || designQuestion.context) //||
                    // !designQuestion.options.find(
                    //   (option) => option.en || option.fr
                    // )
                  }
                >
                  Add Question
                </Button>
              </Grid>
              <Grid item md={6}>
                <QuestionComponent
                  questions={questions}
                  editQuestion={editQuestion}
                  deleteQuestion={deleteQuestion}
                />
              </Grid>
            </Grid>
          </Container>
          <Container
            className="survey-section"
            style={{
              display: activeStep === 2 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className="step-title">
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <QuestionComponent
              questions={questions}
              editQuestion={editQuestion}
              deleteQuestion={deleteQuestion}
            />
          </Container>
          <Container
            className="survey-section"
            style={{
              display: activeStep === 3 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className="step-title">
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <QuestionComponent
              questions={questions}
              editQuestion={editQuestion}
              deleteQuestion={deleteQuestion}
              viewOnly={true}
            />
          </Container>
          <Container className="btnGroup-container">
            <Button
              variant="contained"
              onClick={activeStep === 3 ? props.closeNewSurvey : handleBack}
              className={classes.button}
              style={{ display: activeStep === 0 ? "none" : "inline-block" }}
            >
              {activeStep === 3 ? "Cancel" : "Back"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={
                (activeStep === 0 &&
                  frTitle.trim() === "" &&
                  enTitle.trim() === "") ||
                (activeStep === 1 &&
                  (designQuestion.type === 3 || designQuestion.type === 2
                    ? designQuestion.options.length < 2
                    : false))
              }
              className={classes.button}
            >
              {activeStep === 2 ? "Save as draft" : "Next"}
            </Button>
          </Container>
        </form>
      </Paper>
    </React.Fragment>
  );
}

export default NewSurvey;
