import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import DoctorsInfo from "./DoctorsInfo";
import NewDoctor from "./NewDoctor";
import ClinicDataService from "../services/ClinicDataService";
import { useAppContext } from "../services/authContext";

function Doctors(props) {
  const [newDoc, setNewDoc] = useState(false);
  const { clinicId } = useAppContext();
  const [doctorList, setDoctorList] = useState([]);

  useEffect(() => {
    populateClinicDoctors();
  }, []);

  function populateClinicDoctors() {
    ClinicDataService.findDoctorsByClinicId(clinicId).then(
      (response) => {
        setDoctorList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function openNewDoc() {
    setNewDoc(true);
  }

  function closeNewDoc() {
    console.log("close");
    setNewDoc(false);
  }

  return (
    <Box>
      {newDoc ? (
        <NewDoctor
          closeNewDoc={closeNewDoc}
          populateClinicDoctors={populateClinicDoctors}
        />
      ) : (
        <DoctorsInfo
          openNewDoc={openNewDoc}
          doctorList={doctorList}
          populateClinicDoctors={populateClinicDoctors}
          setDoctorList={setDoctorList}
        />
      )}
    </Box>
  );
}

export default Doctors;
